<template>
    <div class="base">
        <div class="main-body flex-column">
            <div class="text-center mt-3">
                <img :src="require('../assets/images/horizontal_logo.png')" width="250" height="50">
            </div> 

            <div class="d-flex justify-content-center align-items-center h-100">
                <b-spinner variant="info" label="Spinning" v-if="isLoading"></b-spinner>

                <div class="text-center" v-else>

                    <div v-if="activateStatus">
                        <div>
                            <inline-svg
                                :src="require('../assets/icon/checked.svg')"
                                fill="black"
                                height="150"
                                width="150"
                                aria-label="copy"
                            ></inline-svg>
                            <!-- <b-icon-check-lg scale="3"></b-icon-check-lg> -->
                        </div>
                        <br>
                        <h3 class="mt-3 text-success">{{$t("Activate Account Success")}}</h3>

                        <button class="btn-common mt-2" @click="redirectToLogin">{{$t("Go to login")}}</button>
                    </div>

                    <div v-else>
                        <div>
                            <inline-svg
                                :src="require('../assets/icon/cancel-red.svg')"
                                fill="black"
                                height="150"
                                width="150"
                                aria-label="copy"
                            ></inline-svg>
                            <!-- <b-icon-check-lg scale="3"></b-icon-check-lg> -->
                        </div>
                        <br>
                        <h3 class="mt-3 text-danger">{{$t("Activate Account Fail")}}</h3>
                        <p>{{errorMessage}}</p>

                        <!-- <button class="btn-common mt-2" @click="redirectToLogin">Go to login</button> -->
                    </div>
                </div>
                
            </div>
            

        </div>
    </div>
</template>
<script>
    import axios from "axios";  
    import api from '../helper/api.js';
    //  
    import InlineSvg from "vue-inline-svg";

    export default {
        components:{
            InlineSvg,
        },
        name: "Activate",
        data(){
            return{
                isLoading:true,
                activateStatus:true,
                errorMessage:""
            }
        },
        mounted() {
            this.activateAccount();
        },
        methods: {
            async activateAccount(){
                try{
                    const baseUrl = process.env.VUE_APP_CORE_BASE_URL;

                    const response = await axios.get(`${baseUrl}/operators/verify`,{
                        params:{
                            token : this.$route.query.token
                        }
                    });

                    console.log(response);

                    this.isLoading = false;
                    this.activateStatus = true;

                }catch(error){
                    console.error(error);
                    this.activateStatus = false;
                    this.isLoading = false;
                    this.errorMessage = error.response.data.message;

                    this.$swal({
                        text: error.response.data.message,
                        icon: 'error'
                    })
                }
            },
            async getUserType(){
                try{
                    const response = await api.apiCall('core','GET','/operators');
                    localStorage.setItem("userType",response.message.service_type);        
                    localStorage.setItem("platform_id",response.message.platform_id);
                    localStorage.setItem("google2fa",response.message.google2fa);
                    localStorage.setItem("userEmail",response.message.email);
                    localStorage.setItem('swagger',response.message.swagger);
                    localStorage.setItem("chargeUsd",response.message.charge_usd);
                    // localStorage.setItem("viewOperator",response.message.permission.SETTING || response.message.permission.INVITE);
                    localStorage.setItem('inviteOperator',response.message.permission.INVITE);
                    localStorage.setItem('settingOperator',response.message.permission.SETTING);
                }catch(error){
                    console.error(error);
                }
            },

            redirectToLogin(){
                this.$router.push({'name':"Login"});
            }
        },
    }
</script>
<style scoped>
@import '../css/styles.css';
.main-body{
    height: 100vh;
}
</style>